import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react';
import Results from './Results';
import Results_all from './Results_all';

function App() {
    const [input, setInput] = useState("");
    const [search, toggleSearch] = useState(false);
    const [showResults, toggleShowResults] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        if ( input !== "" && input.length > 2 ){
            toggleSearch(true)
            toggleShowResults(true)
        }

    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>

            <main>

                <Results_all/>

                <div className="button-wrapper">
                    <a
                        className="App-link"
                        href="https://hamburgeroriginale.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="link-button">
                            ZUR WEBSITE
                        </div>
                    </a>
                    <p className="caption">
                        Der Hamburger Originale
                    </p>
                    <a className="caption" href="https://hamburgeroriginale.com/impressum/">
                        Impressum
                    </a>
                </div>

            </main>
        </div>
    );
}

export default App;
