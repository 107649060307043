import useGoogleSheets from 'use-google-sheets';
import './Results.css';

export default function Results(props) {
    const { data, loading, error } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsNames: ['Partnerliste!A:C'],
    });
        if (data){
            if (loading) { return <p>loading...</p> }
            if (error) { return <p>error...</p> }
            if (data && data[0].data) { 
                return (<>{
                    data[0].data.map((row, index) => {
                        return (
                            <>
                            { row.WEBSITE ?
                                (
                                    <a href={`${row.WEBSITE}`} key={row.PARTNER+index} target="_blank">
                                        <div className="row" key={row.PARTNER+index}>
                                            { row.LOGO &&
                                            <img src={row.LOGO} className="row-image" alt="logo" width="132" height="62"/>
                                            }
                                        </div>
                                    </a>
                                )
                                :
                                (
                                    <div className="row" key={row.PARTNER+index}>
                                        { row.LOGO &&
                                        <img src={row.LOGO} className="row-image" alt="logo" width="132" height="62"/>
                                        }
                                    </div>
                                )
                            }

                            </>
                        )
                    })
                    }</>)
            }
        }

}
